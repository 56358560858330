.loader-container {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}
.loader {
	width: 100px;
	height: 100px;
	border-radius: 10px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.table-body-min-height {
	min-height: auto; /* Set your desired minimum height */
}
