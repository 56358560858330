/* Common CSS Start */
html,body{
  height: 100%;
}
body {
  padding-bottom: env(safe-area-inset-bottom);
}
.ck-content {
  background-color: #cdcdcd !important;
}
.mw-112 {
  min-width: 7rem;
}
@media (min-width: 992px) {
  html {
    font-size: 75%;
  }
}
@media (min-width: 1200px) {
  html {
    font-size: 68%;
  }
}
@media (min-width: 1300px) {
  html {
    font-size: 74%;
  }
}
@media (min-width: 1400px) {
  html {
    font-size: 76%;
  }
}
@media (min-width: 1600px) {
  html {
    font-size: 90%;
  }
}
@media (min-width: 1800px) {
  html {
    font-size: 100%;
  }
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  -webkit-appearance: none;
  border-radius: 10px;
}

::-webkit-scrollbar-track {
  background: #2d2d2d;
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
}

::-webkit-scrollbar-thumb {
  background: #aaffae;
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
}

.btn-primary:hover svg path[fill="white"] {
  fill: #003464;
}
.text-purpule {
  color: #7536a7;
}
.text-green {
  color: #419f20;
}
@media (min-width: 768px) {
  .fs-36 {
    font-size: 2.25rem;
  }
}
.page-table {
  border-style: hidden;
}
.page-table thead tr th {
  background-color: #f7f7f7;
  color: #4a4a4a;
  font-family: "Nunito Sans Bold";
  padding-top: 1.125rem;
  padding-bottom: 1.125rem;
}
.page-table thead tr th:first-child,
.page-table tbody tr td:first-child {
  padding-left: 1.25rem;
}
.page-table tbody tr td {
  color: #000;
  padding-top: 0.938rem;
  padding-bottom: 0.938rem;
}

.page-table-pagination .page-link {
  width: 2.25rem;
  height: 2.25rem;
  line-height: 2.25rem;
  text-align: center;
  margin: 0 0.75rem;
  padding: 0;
  border-radius: 0.313rem;
  -webkit-border-radius: 0.313rem;
  -moz-border-radius: 0.313rem;
  -ms-border-radius: 0.313rem;
  -o-border-radius: 0.313rem;
}
.page-table-pagination li:first-child .page-link {
  margin-left: 0;
}
.page-table-pagination li:last-child .page-link {
  margin-right: 0;
}
.page-table-pagination .page-link:hover {
  border-color: #003464;
  color: #003464;
}
.form-check-input {
  background-color: #dddddd;
  border-color: #dddddd;
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e") !important;
}
.form-check-input:checked {
  background-color: #419f20;
  border-color: #419f20;
}

.form-check-input[type="radio"] {
  background-color: #fff;
  border-color: #b2b2b2;
  border-width: 0.125rem;
}
.form-check-input:checked[type="radio"] {
  border-color: #003464;
}

.bigSwitch .form-check-input {
  height: 1.688rem;
  width: 3rem;
}

.switch_column .ant-switch {
  height: 1.688rem;
  min-width: 3rem;
}
.switch_column .ant-switch-handle {
  width: 1.25rem;
  height: 1.25rem;
  border-radius: 100%;
  overflow: hidden;
  top: 0.2rem;
  inset-inline-start: 0.188rem;
}
.switch_column .ant-switch.ant-switch-checked .ant-switch-handle {
  inset-inline-start: calc(100% - 1.5rem);
}
.switch_column .ant-switch.ant-switch-checked {
  background: #419f20;
}
/* Modal CSS Start */
.pageCommonModal .modal-header {
  padding-top: 1.5rem;
  padding-bottom: 0.5rem;
}
.pageCommonModal .modal-footer {
  padding-bottom: 1.5rem;
  padding-top: 0.5rem;
}
.pageCommonModal .btn-close {
  right: 1.25rem;
  top: 1.25rem;
  margin: 0;
  padding: 0;
  opacity: 1;
}
.pageCommonModal .modal-content {
  /*background-color: #f2f2f2;
  */
  border: 0;
  border-radius: 0.625rem;
  -webkit-border-radius: 0.625rem;
  -moz-border-radius: 0.625rem;
  -ms-border-radius: 0.625rem;
  -o-border-radius: 0.625rem;
}
.uploadBox {
  border: 1px solid #003464;
  padding: 1.5rem;
  background-color: #f6fbff;
}
@media (min-width: 576px) {
  .pageCommonModal .modal-body {
    padding: 0.75rem 2.5rem;
  }
  .modal-card .card-body {
    padding: 1.25rem;
  }
  .pageCommonModal .modal-header {
    padding-top: 2.5rem;
    padding-bottom: 0.75rem;
  }
  .pageCommonModal .modal-footer {
    padding-bottom: 2.5rem;
    padding-top: 0.75rem;
  }
}

@media (min-width: 1200px) {
  .biglModal .modal-xl {
    --bs-modal-width: 83.75rem;
  }
}
@media (max-width: 991.98px) {
  .modal-fullscreen-lg-down .modal-content {
    border-radius: 0;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
  }
}
/* Modal CSS  End*/

.customSelect .ant-select-arrow {
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14"><path d="M0.249876 4.18267L6.2221 11.1521C6.31836 11.2644 6.43778 11.3545 6.57215 11.4163C6.70652 11.4781 6.85267 11.5101 7.00056 11.5101C7.14846 11.5101 7.29461 11.4781 7.42898 11.4163C7.56335 11.3545 7.68276 11.2644 7.77903 11.1521L13.7512 4.18267C14.3212 3.51743 13.8487 2.48987 12.9728 2.48987H1.02663C0.150752 2.48987 -0.321796 3.51743 0.249876 4.18267Z" fill="%23003464"/></svg>');
  font-size: 0.875rem;
  width: 0.875rem;
  height: 0.75rem;
  background-size: 0.875rem;
  background-repeat: no-repeat;
  margin-top: -0.375rem;
}
.customSelect .ant-select-arrow .anticon > svg {
  display: none;
}
/* Common CSS End */

/* Login Page CSS Start */
.login-container::before {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #aaffae;
  clip-path: polygon(0 0, 100% 0, 100% 35%, 0 50%);
}
.login-card .card-body {
  padding: 1.875rem 1.25rem;
}
.login_form {
  width: 33.75rem;
}
.loginLogo {
  margin-bottom: 2.5rem;
}
.loginLogo img {
  width: auto;
  height: 6.875rem;
}
.pssswordShow {
  top: 0.813rem;
  right: 1.25rem;
  line-height: 1;
}
.pssswordShow svg {
  width: 1.25rem;
  height: 1.25rem;
}
@media (min-width: 576px) {
  .login-card .card-body {
    padding: 2.5rem 3.125rem;
  }
  .loginLogo {
    margin-bottom: 3.125rem;
  }
  .loginLogo img {
    height: 12.375rem;
  }
  .login-container::before {
    clip-path: polygon(0 0, 100% 0, 100% 45%, 0 70%);
  }
}
@media (max-width: 767.98px) {
  .login_form {
    width: 28rem;
  }
}
/* Login Page CSS End */

/* Side Menubar Start */
:root {
  --navbar-vertical-width: 20rem;
}
.navbar-vertical {
  width: var(--navbar-vertical-width);
  background-color: #aaffae;
  z-index: 1021;
  opacity: 0;
  transform: translateX(-100%);
  -webkit-transform: translateX(-100%);
  -moz-transform: translateX(-100%);
  -ms-transform: translateX(-100%);
  -o-transform: translateX(-100%);
  --bs-navbar-padding-y: 0;
  bottom: 60px;
  padding-bottom: calc(env(safe-area-inset-bottom) + 0); 
  transition: transform 0.3s ease, opacity 0.3s ease;
  -webkit-transition: transform 0.3s ease, opacity 0.3s ease;
  -moz-transition: transform 0.3s ease, opacity 0.3s ease;
  -ms-transition: transform 0.3s ease, opacity 0.3s ease;
  -o-transition: transform 0.3s ease, opacity 0.3s ease;
}
.page-content {
  position: relative;
  margin-top: 4rem;
  transition: margin-left 0.2s ease, margin-top 0.2s ease;
  -webkit-transition: margin-left 0.2s ease, margin-top 0.2s ease;
  -moz-transition: margin-left 0.2s ease, margin-top 0.2s ease;
  -ms-transition: margin-left 0.2s ease, margin-top 0.2s ease;
  -o-transition: margin-left 0.2s ease, margin-top 0.2s ease;
}
.px-40 {
  padding-left: 1rem;
  padding-right: 1rem;
}
.py-40 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}
.device-header {
  z-index: 1020;
  /* height: 3rem; */
  background-color: #aaffae;
}
.device-header .mb-navbar-logo {
  height: 3rem;
  width: auto;
}
.navbar-vertical-header {
  border-bottom: 1px solid #003464;
  padding: 1.25rem 0;
}
.navbar-vertical-header img.navbar-logo {
  height: 6.25rem;
}

.navbar-vertical-content .nav-link svg {
  width: 1.5rem;
  height: 1.5rem;
  flex: 0 0 1.5rem;
  margin-right: 0.625rem;
}

.navbar-vertical-content .submenu-nav .nav-link {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: #fff;
  position: relative;
}
.submenu-nav .nav-link::before {
  content: "";
  width: 0.375rem;
  height: 0.375rem;
  border-radius: 100%;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  -ms-border-radius: 100%;
  -o-border-radius: 100%;
  position: absolute;
  background-color: transparent;
  left: 2%;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
}
.navbar-vertical-content .nav-link {
  padding: 1.25rem 1.875rem;
  color: #003464;
}
.navbar-vertical-content .nav-link span.menuIcon {
  width: 2.125rem;
  height: 2.125rem;
  background-color: #003464;
  position: relative;
  margin-right: 0.625rem;
  border-radius: 0.313rem;
  -webkit-border-radius: 0.313rem;
  -moz-border-radius: 0.313rem;
  -ms-border-radius: 0.313rem;
  -o-border-radius: 0.313rem;
}
.navbar-vertical-content .nav-link span.menuIcon svg {
  width: 1.125rem;
  height: 1.125rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
}
.navbar-vertical-content .nav-link.active {
  background-color: #ffffff;
}

.navbar-vertical-footer {
  padding-left: 1.875rem;
  padding-right: 1.875rem;
}
.navbar-vertical-footer svg {
  width: 1.25rem;
  height: 1.25rem;
  margin-right: 0.75rem;
}
@media (min-width: 992px) {
  .navbar-vertical {
    opacity: 1;
    transform: translateX(0);
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
  }
  .page-content {
    margin-left: var(--navbar-vertical-width);
    margin-top: 0;
  }
}
@media (min-width: 992px) {
  .px-40 {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }
  .py-40 {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }
}
@media (max-width: 991.98px) {
  .navbar-vertical.navbar-open {
    opacity: 1;
    transform: translateX(0);
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
  }
  .page-content.min-vh-100 {
    min-height: calc(100vh - 4rem) !important;
  }

  .overlay-open {
    overflow: hidden;
  }
  .overlay-open .page-content::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1019;
    background-color: rgba(0, 0, 0, 0.5);
    transition: background-color 0.3s ease;
    -webkit-transition: background-color 0.3s ease;
    -moz-transition: background-color 0.3s ease;
    -ms-transition: background-color 0.3s ease;
    -o-transition: background-color 0.3s ease;
  }
}
@media (max-width: 767.98px) {
  :root {
    --navbar-vertical-width: 16rem;
  }
  .navbar-vertical-header {
    padding: 0.465rem !important;
  }
  .navbar-vertical-header img.navbar-logo {
    height: 3rem;
    width: auto;
  }
  .navbar-vertical-content .nav-link span.menuIcon {
    width: 1.75rem;
    height: 1.75rem;
    margin-right: 0.5rem;
  }
  .navbar-vertical-content .nav-link span.menuIcon svg {
    width: 0.875rem;
    height: 0.875rem;
  }
  .navbar-vertical-content .nav-link {
    padding: 0.75rem 1rem;
    font-size: 1rem !important;
  }
  .navbar-vertical {
    padding-bottom: calc(env(safe-area-inset-bottom) + 80px);
  }
  .navbar-vertical-footer{
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
/* Side Menubar End */

/* Device Header Start */
.menu-animate-btn {
  cursor: pointer;
  height: 1.75rem;
  width: 1.75rem;
}

.menu-animate-btn span,
.menu-animate-btn span:before,
.menu-animate-btn span:after {
  background-color: #003464;
  width: 1.75rem;
  height: 0.188rem;
  border-radius: 0.5rem;
  -webkit-border-radius: 0.5rem;
  -moz-border-radius: 0.5rem;
  -ms-border-radius: 0.5rem;
  -o-border-radius: 0.5rem;
  transition: background-color 0.2s ease-in-out, margin-top 0.4s ease-in-out,
    transform 0.5s ease-in-out;
  -webkit-transition: background-color 0.2s ease-in-out,
    margin-top 0.4s ease-in-out, transform 0.5s ease-in-out;
  -moz-transition: background-color 0.2s ease-in-out,
    margin-top 0.4s ease-in-out, transform 0.5s ease-in-out;
  -ms-transition: background-color 0.2s ease-in-out, margin-top 0.4s ease-in-out,
    transform 0.5s ease-in-out;
  -o-transition: background-color 0.2s ease-in-out, margin-top 0.4s ease-in-out,
    transform 0.5s ease-in-out;
}
.menu-animate-btn span:before,
.menu-animate-btn span:after {
  content: "";
  position: absolute;
}
.menu-animate-btn span:before {
  margin-top: 0.5rem;
}
.menu-animate-btn span:after {
  margin-top: -0.5rem;
}
.menu-animate-btn.menu-open span {
  background-color: transparent;
}
.menu-animate-btn.menu-open span:before {
  margin-top: 0;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
}
.menu-animate-btn.menu-open span:after {
  margin-top: 0;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
}
@media (max-width: 991.98px) {
  .navbar-vertical-header .menu-animate-btn {
    top: 50%;
    transform: translateY(-50%);
    left: 5%;
    transition: left 0.5s ease-in-out;
    -webkit-transition: left 0.5s ease-in-out;
    -moz-transition: left 0.5s ease-in-out;
    -ms-transition: left 0.5s ease-in-out;
    -o-transition: left 0.5s ease-in-out;
  }
  .navbar-vertical.navbar-open .navbar-vertical-header .menu-animate-btn {
    left: 83%;
  }
}
/* Device Header End */

/* Dashboard  */
.dash__card svg {
  width: 2.25rem;
  height: 2.25rem;
  flex: 0 0 2.25rem;
  margin-right: 0.75rem;
  border-radius: 100%;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  -ms-border-radius: 100%;
  -o-border-radius: 100%;
}

@media (min-width: 576px) {
  .dash__card .card-body {
    padding: 1.25rem 1.5rem;
  }
  .dash__card svg + span {
    width: calc(100% - 2.25rem - 0.75rem);
  }
}
@media (max-width: 575.98px) {
  .dash__card .card-body {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .dash__card .fs-6 {
    font-size: 0.875rem !important;
  }
  .dash__card svg {
    margin: 0 auto;
    margin-bottom: 0.75rem;
  }
  .dash__card .fs-36 {
    font-size: 1.15rem;
  }
  .dash__card .card-body .d-flex {
    flex-direction: column;
    justify-content: center;
  }
}
/* Dashboard  */

/* Bill management  */
.allCustomerSelect {
  width: 16.25rem;
  height: 2.875rem;
}
.allCustomerSelect .ant-select-selector {
  border-color: #e5e5e5 !important;
}
.billStatusSelect {
  width: 10rem;
}
.dateSelectFilter {
  width: 23.75rem;
}
.pageSearch {
  width: 15rem;
}
.pageSearch .searchIcon {
  position: absolute;
  width: 1.25rem;
  height: 1.25rem;
  top: 0.813rem;
  right: 1.25rem;
}
.pageSearch input.form-control {
  padding-right: 3rem;
}
.action-Btn {
  width: 2rem;
  height: 2rem;
  flex: 0 0 2rem;
  padding: 0;
  position: relative;
}
.action-Btn svg {
  width: 1rem;
  height: 1rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
}
.action-Btn:hover svg path {
  fill: #ffffff;
}
.btn-outline-secondary.action-Btn {
  --bs-btn-border-color: #b1b1b1;
}
.billStatus {
  padding: 0.5rem 0.75rem;
  font-size: 0.75rem;
  border-radius: 5rem;
  -webkit-border-radius: 5rem;
  -moz-border-radius: 5rem;
  -ms-border-radius: 5rem;
  -o-border-radius: 5rem;
}
.billStatus svg {
  width: 0.625rem;
  height: 0.625rem;
  margin-right: 0.375rem;
}
.billStatus.unpaidStatus {
  background-color: #f7f7f7;
  color: #949494;
}
.billStatus.overdueStatus {
  background-color: #fff0f0;
  color: #f33636;
}
.billStatus.paidStatus {
  background-color: #e9ffe7;
  color: #419f20;
}
.billStatus.paidStatus svg circle {
  fill: #419f20;
}
.billStatus.activeStatus {
  background-color: #e9ffe7;
  color: #419f20;
}
.billStatus.activeStatus svg circle {
  fill: #419f20;
}

.callEmail {
  border: 1px solid #003464;
  position: relative;
  font-size: 0.75rem;
  height: 1.375rem;
  width: 1.375rem;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  border-radius: 0.313rem;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
}
.callEmail svg {
  width: 0.625rem;
  height: 0.625rem;
  margin: 0 0.375rem;
  z-index: 1;
}
.callEmail a {
  /* width: 1.375rem; */
  height: 1.375rem;
  white-space: nowrap;
  border: 0.063rem solid #003464;
  color: #000;
  border-color: transparent;
  border-radius: 0.313rem;
  position: absolute;
  padding-left: 1.25rem;
  left: -0.063rem;
  padding-right: 0;
  z-index: 2;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
}
.callEmail a span {
  opacity: 0;
  visibility: hidden;
  width: 0;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
}
.callEmail:hover {
  border-color: transparent;
}
.callEmail:hover a {
  border-color: #003464;
  padding-right: 0.375rem;
  background-color: #ffffff;
}
.callEmail:hover a span {
  opacity: 1;
  visibility: visible;
  width: auto;
}
.callEmail:hover svg {
  z-index: 3;
}

@media (max-width: 575.98px) {
  .allCustomerSelect {
    width: 65%;
  }
  .billStatusSelect {
    width: 31%;
  }
  .pageSearch,
  .dateSelectFilter {
    width: 100%;
  }
  .allCustomerSelect,
  .billStatusSelect {
    margin-bottom: 1rem;
  }
}
/* Bill management  */

/* Custom Range Picker */
.RangeDatePicker {
  border: 1px solid #dcdcdc;
  height: 2.875rem;
}

.RangeDatePicker.is-active .ant-picker-clear {
  opacity: 1;
}

.antdRangePicker .ant-picker-input input::placeholder {
  font-size: 1rem;
  color: #9b9b9b;
}

.antdRangePicker .ant-picker-input input {
  font-size: 1rem;

  line-height: normal !important;
}

.antdRangePicker.selected .ant-picker-clear {
  opacity: 1;
}

/* Bid Device  */
@media (max-width: 767.98px) {
  .ant-picker-dropdown {
    max-width: calc(100% - 2rem);
  }
  .ant-picker-dropdown .ant-picker-panel-layout {
    overflow-x: auto;
  }
}
/* Common Mobile  */
@media (max-width: 575.98px) {
  .page-title {
    font-size: 1.5rem;
  }
  h4.fs-4{
    font-size: 1.25rem !important;
  }
  .card .card-header .fs-4 {
    font-size: 1.25rem !important;
  }
  .card .card-header .fs-5 {
    font-size: 0.875rem !important;
  }
  .card .card-header a {
    white-space: nowrap;
  }
  .table.page-table {
    font-size: 0.75rem !important;
  }
  .table-card .card-body {
    padding: 0 !important;
  }
  .table-card .card-body .rounded-3 {
    border-radius: 0 !important;
    -webkit-border-radius: 0 !important;
    -moz-border-radius: 0 !important;
    -ms-border-radius: 0 !important;
    -o-border-radius: 0 !important;
  }
  .page-table thead tr th:first-child,
  .page-table tbody tr td:first-child {
    padding-left: 0.875rem;
  }
}

.uploadPreviewBorder {
  border: 1px dashed #d9d9d9;
}
