/*Common CSS Start*/
@media (min-width: 768px) {
	html {
		font-size: 74%;
	}
}
@media (min-width: 1200px) {
	html {
		font-size: 68%;
	}
}
@media (min-width: 1300px) {
	html {
		font-size: 74%;
	}
}
@media (min-width: 1400px) {
	html {
		font-size: 76%;
	}
}
@media (min-width: 1600px) {
	html {
		font-size: 90%;
	}
}
@media (min-width: 1800px) {
	html {
		font-size: 100%;
	}
}

@media (max-width: 320px) {
	html {
		font-size: 90%;
	}
}

.customScroll::-webkit-scrollbar {
	width: 0.375rem;
	background-color: #d9d9d9;
	border-radius: 1rem;
}

.customScroll::-webkit-scrollbar-track {
	border-radius: 1rem;
	background-color: #d9d9d9;
}
.customScroll::-webkit-scrollbar-thumb {
	border-radius: 1rem;
	background-color: #707070;
}

.fs-18 {
	font-size: 1.125rem;
}

.fs-11 {
	font-size: 0.688rem;
}

.fs-12 {
	font-size: 0.75rem;
}

.fs-14 {
	font-size: 0.875rem;
}

.fs-10 {
	font-size: 0.625rem;
}

.text-truncate-2 {
	overflow: hidden;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 2;
	white-space: pre-wrap;
}

.text-truncate-3 {
	overflow: hidden;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 3;
	white-space: pre-wrap;
}

.text-truncate-6 {
	overflow: hidden;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 6;
	white-space: pre-wrap;
}

/* Border Radius */
.border-s {
	border-radius: 0.313rem !important;
}

.border-m {
	border-radius: 0.625rem !important;
}

.border-1 {
	border-width: 0.063rem;
	border-style: solid;
}

.border-5 {
	border-width: 0.094rem;
	border-style: solid;
}

.border-2 {
	border-width: 0.125rem;
	border-style: solid;
}

.ff {
	font-family: "Nunito Sans Regular";
	font-weight: normal;
}
.ff_md {
	font-family: "Nunito Sans Medium";
	font-weight: 500;
}
.ff_sb {
	font-family: "Nunito Sans SemiBold";
	font-weight: 600;
}
.ff_b {
	font-family: "Nunito Sans Bold";
	font-weight: bold;
}
.ff_eb {
	font-family: "Nunito Sans ExtraBold";
	font-weight: bold;
}

/*  */
.ant-switch.ant-switch-checked:hover:not(.ant-switch-disabled),
.ant-switch.ant-switch-checked {
	background: #00b96b;
}

.ant-table-wrapper,
.ant-table-row {
	font-family: "Nunito Sans Regular";
}

/* antd model  */
.antdModal .antdModalTitle {
	font-weight: 700;
	font-size: 1.75rem;
	margin: 0 auto;
	max-width: 85%;
	text-align: center;
	line-height: 2.383rem;
}
.modelMaxHeight .ant-modal-content {
	/* max-height: min(80vh, 700px); */
	/* max-height: 500px; */
	/* overflow-y: scroll; */
	/* padding: 20px 20px !important; */
	/* padding-right: 0px !important; */

	/* z-index: 1038 !important; */
}

.modelMaxHeight .ant-modal-content::-webkit-scrollbar {
	border-radius: 0px 6px 6px 0px !important;
	background-color: #fff !important;
}
