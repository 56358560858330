.formik-input .formik-icon {
	position: absolute;
	right: 0.5rem;
	/* top: 0.5rem; */
	top: calc(50% - 11px);
}

.formik-input .formik-icon svg {
	height: 1rem;
}
