html,
body,
#root,
.ant-app {
	/* Add Font Family */
	font-family: "Nunito Sans Regular";
}

:root {
	--primary: #3961e5;
	--primary-bg: #ebf0f6;
}

.primary-bg {
	background-color: var(--primary-bg) !important;
}
